import React from "react";
//import Link from "gatsby-link";
//import $ from "jquery/dist/jquery";
import codepenStyles from "../styles/components/codepen-featured.module.scss";
import floatingAnimation from "../../static/floating_animation.jpg";
import floatingAnimation05 from "../../static/floating_animation-x05.jpg";
import thumbnail from "../../static/3d-thumbnail.jpg";
import thumbnail05 from "../../static/3d-thumbnail-x05.jpg";
import theDark from "../../static/the-dark.jpg";
import theDark05 from "../../static/the-dark-x05.jpg";
import heroAnimation from "../../static/hero-animation.jpg";
import heroAnimation05 from "../../static/hero-animation-x05.jpg";
import fire from "../../static/fire.jpg";
import fire05 from "../../static/fire-x05.jpg";
import musicPlayer from "../../static/music-player.jpg";
import musicPlayer05 from "../../static/music-player-x05.jpg";

class CodepenFeatured extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            codepenData: {} 
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <section className={codepenStyles.codepenFeatured}>
                <div className={codepenStyles.inner}>
                    <h1>On my CodePen</h1>

                    <div className={codepenStyles.penContainer}>
                        <div className={codepenStyles.pen}>
                            <a href="https://codepen.io/MarioDesigns/pen/woJgeo" target="_blank" rel="noopener noreferrer">&nbsp;</a>
                            <picture>
                                <source srcSet={floatingAnimation05} media="(min-width: 800px)" />
                                <img src={floatingAnimation} alt="Floating css animation" />
                            </picture>
                            <div className={codepenStyles.content}>
                                <h3>FloatingAnimation - CSS</h3>
                                <p>Simple floating CSS animation using transform and box-shadow.</p>
                                <a className={codepenStyles.penLink} href="https://codepen.io/MarioDesigns/pen/woJgeo" target="_blank" rel="noopener noreferrer">View pen</a>
                            </div>
                        </div>
                        <div className={codepenStyles.pen}>
                            <a href="https://codepen.io/MarioDesigns/pen/OjQKyx" target="_blank" rel="noopener noreferrer">&nbsp;</a>
                            <picture>
                                <source srcSet={theDark05} media="(min-width: 800px)" />
                                <img src={theDark} alt="The Dark - CSS animated background" />
                            </picture>
                            <div className={codepenStyles.content}>
                                <h3>The Dark - CSS animated background</h3>
                                <p>Medium/Advanced CSS animation of background particles into a beautiful illustration created by me.<br/>JS used to randomly create the different particles required.</p>
                                <a className={codepenStyles.penLink} href="https://codepen.io/MarioDesigns/pen/OjQKyx" target="_blank" rel="noopener noreferrer">View pen</a>
                            </div>
                        </div>
                        <div className={codepenStyles.pen}>
                            <a href="https://codepen.io/MarioDesigns/pen/NdygoP" target="_blank" rel="noopener noreferrer">&nbsp;</a>
                            <picture>
                                <source srcSet={thumbnail05} media="(min-width: 800px)" />
                                <img src={thumbnail} alt="Interactive 3D Video Thumbnail" />
                            </picture>
                            <div className={codepenStyles.content}>
                                <h3>Interactive 3D Video Thumbnail</h3>
                                <p>An interactive 3D video thumbnail that reacts to your mouse hovers, give it a go, hover your mouse over the thumbnail and see it in action!</p>
                                <a className={codepenStyles.penLink} href="https://codepen.io/MarioDesigns/pen/NdygoP" target="_blank" rel="noopener noreferrer">View pen</a>
                            </div>
                        </div>

                        <div className={codepenStyles.pen}>
                            <a href="https://codepen.io/MarioDesigns/pen/Ndqxbm" target="_blank" rel="noopener noreferrer">&nbsp;</a>
                            <picture>
                                <source srcSet={heroAnimation05} media="(min-width: 800px)" />
                                <img src={heroAnimation} alt="Hero Animated Canvas Background" />
                            </picture>
                            <div className={codepenStyles.content}>
                                <h3>Hero Animated Canvas Background</h3>
                                <p>Quick animation prototype to explore an idea for the hero of my personal portfolio.This was created using a canvas as a background where the triangles are drawn and animates with a overlay gradient on top of the canvas.</p>
                                <a className={codepenStyles.penLink} href="https://codepen.io/MarioDesigns/pen/Ndqxbm" target="_blank" rel="noopener noreferrer">View pen</a>
                            </div>
                        </div>
                        <div className={codepenStyles.pen}>
                            <a href="https://codepen.io/MarioDesigns/pen/oYzdjG" target="_blank" rel="noopener noreferrer">&nbsp;</a>
                            <picture>
                                <source srcSet={musicPlayer05} media="(min-width: 800px)" />
                                <img src={musicPlayer} alt="SoundCloud Custom Player" />
                            </picture>
                            <div className={codepenStyles.content}>
                                <h3>SoundCloud Custom Player</h3>
                                <p>First attempt on creating a Custom SoundCloud player using the SoundCloud stream API. </p>
                                <a className={codepenStyles.penLink} href="https://codepen.io/MarioDesigns/pen/oYzdjG" target="_blank" rel="noopener noreferrer">View pen</a>
                            </div>
                        </div>
                        <div className={codepenStyles.pen}>
                            <a href="https://codepen.io/MarioDesigns/pen/mRwvzN" target="_blank" rel="noopener noreferrer">&nbsp;</a>
                            <picture>
                                <source srcSet={fire05} media="(min-width: 800px)" />
                                <img src={fire} alt="Fire!" />
                            </picture>
                            <div className={codepenStyles.content}>
                                <h3>Fire!</h3>
                                <p>Playing around with CSS animation and particles to simulate fire animations.</p>
                                <a className={codepenStyles.penLink} href="https://codepen.io/MarioDesigns/pen/mRwvzN" target="_blank" rel="noopener noreferrer">View pen</a>
                            </div>
                        </div>
                    </div>

                    <p>Want to see more pens?<br/>Check out <a href="https://codepen.io/MarioDesigns/pens/popular" target="_blank" rel="noopener noreferrer">my popular pens</a>, and follow me on <a href="https://codepen.io/MarioDesigns/" target="_blank" rel="noopener noreferrer">CodePen</a> to stay up to date with my latest posts.</p>

                </div>
            </section>
        )
    }

}

export default CodepenFeatured;
import React from "react";
import Link from "gatsby-link";
import "../styles/components/about-home.moule.scss";
import Graphsvg from "../../static/github-graph-2019.svg";

const AboutHomeSection = () => (
    <div>
        <section id="aboutHome" className="about-home-layout">
            <div className="inner">
                <p>Originally from Portugal where I studied visual arts,<br/>to the United Kingdom where I dived deep into tech and coding since 2008 have made of me a developer with a sharp eye for details and design.</p>

                <h3><i>I do what I Love and I Love what I do.</i></h3>

                <p><Link to="/about">Continue reading about me.</Link></p>
            </div>
        </section>
        <div id="aboutHomeGraph">
            <object type="image/svg+xml" data={Graphsvg} className="github-graph">Github Graph</object>
            <p>I am a Senior developer / Tech Lead<br/>Living in London, United Kingdom.<br/>I ❤️ create 🐛 and turn them into 🦋 with JavaScript!<br/><a href="https://github.com/Mario-Duarte/" target="_blank" rel="noopener noreferrer">Follow me on GitHub</a></p>
            
        </div>
    </div>
)

export default AboutHomeSection;
import React from "react";
//import Link from "gatsby-link";
import Layout from "../components/layout";
import Hero from "../components/hero";
import AboutHomeSection from "../components/home-about";
import CodepenFeatured from "../components/codepen-featured";
import "../styles/pages/index.scss";

const IndexPage = () => (
    <Layout>
        <Hero></Hero>
        <AboutHomeSection></AboutHomeSection>
        <CodepenFeatured></CodepenFeatured>
    </Layout>
)

export default IndexPage
